/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import StructuredData from "./StructuredData"

function SEO({ canonical, description, lang, meta, title, keywords, siteUrl, img }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            twitterUsername
            keywords
            siteUrl
            image
            author {
              name
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const image = img || site.siteMetadata.image
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s ` : null}
        link={
          canonical
            ? [{ rel: 'canonical', key: canonical, href: canonical }]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: keywords || site.siteMetadata?.keywords,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: image || site.siteMetadata?.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.twitterUsername || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            property: `og:url`,
            content: siteUrl,
          }

        ].concat(meta)}
      />
      <StructuredData>
        {{
          '@context': 'https://schema.org',
          '@type': 'SoftwareApplication',
          "url": 'https://www.tripleaimsoftware.com/patameds/',
          "name": 'Patameds e-commerce',
          "applicationCategory": "BusinessApplication",
          "operatingSystem": "Windows 7, OSX 10.6, Android 1.6",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4",
            "bestRating": "5",
            "ratingCount": "10"
          }
        }}
      </StructuredData>
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
